<template>
  <div>
    <div>
      <breadcrumb name="Category" title="Category Details" subtitle="Add New Category Specs Details" @onTitleClicked="goBack"/>
    </div>
    <div class="body">
      <vs-row>
        <vs-col vs-lg="6" vs-sm="12">
          <div class="drop-down">
            <label for="category">Choose Category</label>
            <select name="category" id="category" v-model="category_id" style="height: 40px">
              <option value="0" disabled>Choose Category</option>
              <option :value="category.id" v-for="(category, index) in categories" :key="index" v-if="category_id == category.id">{{category.name_en}}</option>
            </select>
          </div>
        </vs-col>
        <vs-col vs-lg="6" vs-sm="12">
          <div class="drop-down">
            <label for="specs">Choose Spec</label>
            <select name="specs" id="specs" v-model="spec_id" style="height: 40px">
              <option value="0" disabled>Choose Spec</option>
              <option :value="spec.id" v-for="(spec, index) in specs" :key="index">{{spec.name_en}}</option>
            </select>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>

        <vs-col vs-lg="6" vs-sm="12">
          <div class="drop-down" v-if="textSpec">
            <label for="specs">Spec Value</label>
            <vs-input v-model="specTextValue" class="input" style="width: 90%"/>
          </div>
          <div class="drop-down" v-if="boolSpec">
            <label for="specs">Spec Value</label>
            <vs-checkbox v-model="specBoolValue" class="checkbox">{{specBoolValue? 'Available': 'Unavailable'}}</vs-checkbox>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-lg="12" vs-sm="12">
          <div class="flex">
            <vs-button
              icon-pack="feather"
              icon="icon-save"
              :disabled="invalidSpec"
              @click="confirmSaveSpec"
            >Save
            </vs-button>
            <vs-button
              type="border"
              class="ml-5 mr-10"
              color="primary"
              @click="resetValues"
            >Cancel
            </vs-button>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
  import ChooseModel from "../../../../../components/custom/choose-model";
  import Breadcrumb from "../../../../../components/general/breadcrumb";

  export default {
    name: 'add-spec',
    components: {Breadcrumb, ChooseModel},
    data() {
      return {
        category_id: this.$route.params.id,
        model_id: '',
        brand_id: '',
        categories: [],
        specs: [],
        spec_id: '0',
        specTextValue: '',
        specBoolValue: true,
        textSpec: false,
        boolSpec: false
      }
    },
    methods: {
      confirmSaveSpec() {
        this.$vs.dialog({
          type: 'confirm',
          color: 'primary',
          title: `Confirm`,
          text: "You're going to save this spec!",
          accept: () => this.saveSpec()
        })
      },
      getValue() {
        if (this.textSpec) {
          return this.specTextValue;
        } else if (this.boolSpec) {
          return this.specBoolValue ? '1' : '0';
        }
      },
      saveSpec() {
        this.$vs.loading();

        let value = this.getValue();

        let payload = {
          category_id: this.category_id,
          spec_id: this.spec_id,
          value: value
        };

        this.$httpCars
          .post("categories/spec", payload)
          .then(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              color: "success",
              text: "Spec is added successfully!"
            })
            this.$router.push(`/categories/add-specs/{this.category_id}`);
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              color: "danger",
              text: "Couldn't Save Spec!"
            })
          });
      },
      goBack()
      {
        this.$router.push(`/categories/show/${this.category_id}`);

      },
      resetValues() {
        this.spec_id = '0';
        this.textSpec = false;
        this.boolSpec = false;
        this.$router.go(-1);
      },
      getSpecs() {
        this.$vs.loading();
        this.$httpCars
          .get("specs?per_page=1000")
          .then(r => {
            this.$vs.loading.close();
            this.specs = r.data.data.data;
            //console.log({specs: this.specs});
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
          });
      },
      getCategories() {
        this.$vs.loading();
        this.$httpCars
          .get("categories")
          .then(r => {
            this.$vs.loading.close();
            this.categories = r.data.data.categories;
            //console.log({categories: this.categories});
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
          });
      },
    },
    computed: {
      invalidSpec() {
        if(this.textSpec && !this.specTextValue){
          return true;
        }

        if ((!this.textSpec && !this.boolSpec) || (!this.specTextValue && this.specBoolValue === null)) {
          return true;
        }
        return false;
      }
    },
    watch: {
      spec_id(value) {
        if (value === '0') {
          this.boolSpec = false;
          this.textSpec = false;
          return;
        }

        for (let i in this.specs) {
          if (this.specs[i].id === value) {
            if (this.specs[i].type === 'bool') {
              this.boolSpec = true;
              this.textSpec = false;
              return;
            } else if (this.specs[i].type === 'text') {
              this.boolSpec = false;
              this.textSpec = true;
              return;
            }
          }
        }
      }
    },
    mounted() {
      this.getCategories();
      this.getSpecs();
    }
  }
</script>

<style scoped lang="scss">
  .body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .drop-down {
    margin-top: 20px;
  }

  label {
    font-size: 1rem;
    padding: 5px;
  }

  select {
    padding: 10px;
    border-radius: 5px;
    width: 90%;
    background-color: #fff;
    border: 1px solid #00000030;
  }

  .input {
    width: 100%;
  }

  .checkbox {
    margin-top: 10px;
  }

  .flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
  }

</style>
